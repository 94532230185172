.footer-container {
  position: relative;
}

.footer-container > hr {
  border: 1px solid lightgray;
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.social-link {
  display: flex;
  gap: 4rem;
}
.social-link > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.logo-f {
  width: 10rem;
}

.blur-f-1 {
  bottom: 0;
  right: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: red;
}
.blur-f-2 {
  bottom: 0;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: rgb(255, 115, 0);
}
@media screen and (max-width: 768px) {
  .logo-f {
    position: relative;
    right: 4rem;
    transform: scale(0.8);
  }
}
